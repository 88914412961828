(function() {
  let platform = window['platform'] || {};
  let ua: string = platform.ua || navigator.userAgent;
  let deviceInfo = window['wvcDeviceInfo'] || {};
  deviceInfo.device = [];

  const imageBase = "https://www.cast2tv.app/receiver-images"

  if (platform.os && platform.os.family) {
    deviceInfo.osVersion = platform.os.family;
    if (platform.os.version) {
      deviceInfo.osVersion += ` ${ platform.os.version }`;
    }
  }

  let model = /\bModel\/([^ -]+)-([^ ]+)/i.exec(ua)
  let netcast = /\bLG Netcast[. ]TV-(20[10][0-9])/i.exec(ua);
  if (model && model.length >= 3) {
    deviceInfo.brand = 'V-' + model[1];
    deviceInfo.model = model[2];
    let store = /\bStore\/([0-9.]+)/i.exec(ua) 
    if (store && store.length >= 2 && platform.name == 'Opera') {
      deviceInfo.icon = `${imageBase}/vewd.png`
      deviceInfo.device.push(`Vewd Store ${store[1]}`);
    }
  } else if (netcast && netcast.length >= 2) {
    let version = netcast[1];
    if (version === '2011') {
        version = '2';
    } else if (version === '2012') {
        version = '3';
    } else if (version === '2013') {
        version = '4';
    }
    deviceInfo.brand = 'LG Netcast';
    let model = /\bLGE; ([^;][^;]*);/i.exec(ua);
    if (model && model.length >= 2) {
      deviceInfo.model = model[1];
    }
    deviceInfo.device.push(`Netcast ${version}`);
    deviceInfo.osVersion = `Netcast ${version} ${deviceInfo.osVersion}`;
    deviceInfo.icon = `${imageBase}/lg.png`
  } else {
    deviceInfo.brand = platform.manufacturer
    if (platform.name.toLocaleLowerCase('en-US') != "safari" || /Macintosh/.test(ua)) {
      deviceInfo.model = platform.name
    } else {
      const uaLower = ua.toLocaleLowerCase('en-US')
      const isSamsung = /samsung/.test(uaLower)
      const isTizen = /tizen/.test(uaLower)
      if (isSamsung || isTizen) {
        deviceInfo.model = "Samsung"
        deviceInfo.icon =  `${imageBase}/tizen.png` 
      }
    }
  }
  if (platform.description) {
    deviceInfo.device.push(platform.description);
  }
  if (window.screen && window.screen.availWidth && window.screen.availHeight) {
    deviceInfo.device.push(window.screen.availWidth + 'x' + window.screen.availHeight);
  }
  

  deviceInfo.build = 'browser';
  var host = document.location.host || document.location.hostname
  if (document.location.protocol !== "file:" && host && host.indexOf(".") > 0) {
    const subdomain = host.substring(0, host.indexOf("."))
    deviceInfo.package = deviceInfo.build + "." + subdomain;
    if (subdomain == 'vidaa') {
      deviceInfo.exitOnBack = true
      deviceInfo.supportedSpeeds = [0, 0.5, 1, 2, 3, 4]
      deviceInfo.icon = `${imageBase}/vidaa.png`
    } else if (subdomain == 'foxxum') {
      deviceInfo.icon = `${imageBase}/foxxum.png`
      if (!document.referrer) {
        deviceInfo.exitOnBack = true
      } else {
        deviceInfo.backToReferrer = true
      }
    } else if (subdomain == "netcast") {
      deviceInfo.wss = false
    }
  }

  // Vidaa
  try {
    if (typeof window["Hisense_GetBrand"] === 'function') {
      deviceInfo.brand = window["Hisense_GetBrand"]();
      deviceInfo.exitOnBack = true
      deviceInfo.supportedSpeeds = [0, 0.5, 1, 2, 3, 4]
    }
    if (typeof window["Hisense_GetModelName"] === 'function')
      deviceInfo.model = window["Hisense_GetModelName"]();
    if (typeof window["Hisense_GetFirmWareVersion"] === 'function')
      deviceInfo.osVersion = window["Hisense_GetFirmWareVersion"]();
  } catch (err) { }

  if (deviceInfo.brand) {
    // replace and replaceAll are not available on older versions of chrome
    const brand = deviceInfo.brand.split('-').join('').split('_').join('').split(' ').join('').toLowerCase()
    for (let type of ["vestel", "hisense", "toshiba", "noblex", "caixun", "philco", "masterg"]) {
      if (brand.indexOf(type) >= 0) {
        deviceInfo.icon = `${imageBase}/${type}.png` 
        break
      }
    }
  }

  if (!deviceInfo.icon && platform.name) {
    const platformName = platform.name.toLowerCase()
    for (let name of ["firefox", "edge", "chrome", "safari"]) {
      if (platformName.indexOf(name) >= 0) {
        if (name != "safari" || /Macintosh/.test(ua)) {
          deviceInfo.icon = `${imageBase}/${name}.png` 
          break
        }
      }
    }
  }

  window['wvcDeviceInfo'] = deviceInfo;
})();